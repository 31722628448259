import React from "react";
import { Link } from "design-system";

function FooterLinks() {
  return (
    <div className="flex items-center justify-center gap-4 px-2 py-2">
      <Link target="_blank" to="/privacy-policy.html">
        隐私条款
      </Link>
      <Link target="_blank" to="/terms-and-conditions.html">
        使用协议
      </Link>
    </div>
  );
}

export default FooterLinks;
