export interface OptionType {
  label?: string;
  value?: string;
}

type OptionTypeWithSubtext = OptionType & {
  subtext?: string;
};

export const proficiencyOptions: OptionTypeWithSubtext[] = [
  {
    label: "小白",
    subtext: "从未写过代码",
    value: "Brand New",
  },
  {
    label: "新手",
    subtext: "了解基础的编程概念",
    value: "Novice",
  },
  {
    label: "中级",
    subtext: "能够处理难度适中的项目",
    value: "Intermediate",
  },
  {
    label: "高级",
    subtext: "有大型复杂项目经验",
    value: "Advanced",
  },
];

export const useCaseOptions: OptionTypeWithSubtext[] = [
  {
    label: "工作项目",
    value: "work project",
  },
  {
    label: "个人项目",
    value: "personal project",
  },
];
