export default [
  {
    sectionName: "",
    id: 1,
    children: [
      {
        label: "页面加载完成后执行",
        configProperty: "executeOnLoad",
        controlType: "SWITCH",
        subtitle: "是否在页面加载完成后执行查询",
      },
      {
        label: "在执行查询前确认",
        configProperty: "confirmBeforeExecute",
        controlType: "SWITCH",
        subtitle: "在每次请求数据前向用户确认",
      },
      {
        label: "查询超时（毫秒）",
        subtitle: "等待查询响应的最大时间",
        configProperty: "actionConfiguration.timeoutInMillisecond",
        controlType: "INPUT_TEXT",
        dataType: "NUMBER",
      },
    ],
  },
];
